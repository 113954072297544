import { Field, FieldArray } from "formik";
import { FormikContext, FormikContextType } from "formik";
import { useContext, useState } from "react";
import { ReportType } from "../../../../types/reports";
import Modal from "../../../common/modal";
import { darkThemeStyles, IngredientType } from "./sections";
import { TableRow } from "../../../common/components/table";
import { InputField, LabelText } from "../../../common/components";
import Button from "../../../common/components/button";
import Select from "react-select";

import CSS from "./sections.module.css";
import { ingredientsData } from "../../../../data/sample-data";
import { CollapsableDiv } from "../common";
import { DashboardContext } from "../../../dashboard/dashboard-context";
import { Recipes } from "../../recipes";

export const Lunch = () => {
  const { values, setFieldValue } =
    useContext<FormikContextType<ReportType>>(FormikContext);

  const [showModal, setShowModal] = useState(false);
  const [recipeIndex, setRecipeIndex] = useState<number | undefined>(undefined);

  const { editReport } = useContext(DashboardContext);

  const [selectedTags, setSelectedTags] = useState<IngredientType[]>(
    editReport?.breakfast.ingredients?.map((tag) => ({
      value: tag,
      label: tag,
    })) ?? []
  );

  return (
    <>
      {showModal && (
        <div>
          <Modal
            onClose={() => setShowModal(false)}
            onCloseText="Close Recipes"
            variant="small"
          >
            <Recipes
              path={`lunch.recipes[${recipeIndex}]`}
              setFieldValue={setFieldValue}
              onClose={() => setShowModal(false)}
            />
          </Modal>
        </div>
      )}

      <CollapsableDiv title="> Instructions">
        <FieldArray
          name="lunch.instructions"
          render={(arrayHelpers) => (
            <div>
              {values.lunch.instructions?.map((instruction, index) => (
                <div key={index}>
                  <TableRow
                    columns={[
                      { data: index + 1, width: 3 },
                      {
                        child: (
                          <span>
                            <Field
                              name={`lunch.instructions[${index}].title`}
                              as={InputField}
                              placeholder="e.g. Eat fiber-rich food"
                            />
                          </span>
                        ),
                        width: 15,
                      },
                      {
                        child: (
                          <span>
                            <Field
                              name={`lunch.instructions[${index}].details`}
                              as={InputField}
                              placeholder="(Optional) Try to eat more vegetables and fruits"
                            />
                          </span>
                        ),
                        width: 45,
                      },
                      {
                        child: (
                          <span>
                            <Field
                              name={`lunch.instructions[${index}].duration`}
                              as={InputField}
                              placeholder="(Days)"
                            />
                          </span>
                        ),
                        width: 10,
                      },
                      {
                        child: (
                          <span className={CSS.tableButton}>
                            <Button
                              type="button"
                              onClick={() => arrayHelpers.remove(index)}
                              mode="secondary"
                              label="Remove"
                            />
                          </span>
                        ),
                        width: 10,
                      },
                    ]}
                  />
                </div>
              ))}

              <div className={CSS.tableButton}>
                <Button
                  onClick={() =>
                    arrayHelpers.push({
                      title: "",
                      instructions: "",
                      duration: 0,
                    })
                  }
                  mode="primary"
                  label="+"
                />
              </div>
            </div>
          )}
        />
      </CollapsableDiv>
      <CollapsableDiv title="> Recipes">
        <FieldArray
          name="lunch.recipes"
          render={(arrayHelpers) => (
            <div>
              {values.lunch.recipes?.map((recipe, index) => (
                <div key={index}>
                  {!recipe.id ? (
                    <div className={CSS.tableRowButton}>
                      <span
                        onClick={() => {
                          setShowModal(true);
                          setRecipeIndex(index);
                        }}
                        className={CSS.textButton}
                      >
                        Select a recipe
                      </span>
                      <span className={CSS.tableButton}>
                        <Button
                          type="button"
                          onClick={() => arrayHelpers.remove(index)}
                          mode="secondary"
                          label="Remove"
                        />
                      </span>
                    </div>
                  ) : (
                    <TableRow
                      columns={[
                        { data: index + 1, width: 3 },
                        { data: recipe.name, width: 15 },
                        {
                          data: recipe.ingredients
                            .map(
                              (ingredient) =>
                                `${ingredient.quantity}  ${
                                  ingredient.unit ?? ""
                                } - ${ingredient.ingredientName}  
                              `
                            )
                            .join(", "),
                          width: 30,
                        },
                        {
                          data: recipe.instructions ?? "-",
                          width: 25,
                        },
                        {
                          child: (
                            <span>
                              <Field
                                name={`lunch.recipes[${index}].duration`}
                                as={InputField}
                                placeholder="(Days)"
                              />
                            </span>
                          ),
                          width: 10,
                        },
                        {
                          child: (
                            <span className={CSS.tableButton}>
                              <Button
                                type="button"
                                onClick={() => arrayHelpers.remove(index)}
                                mode="secondary"
                                label="Remove"
                              />
                            </span>
                          ),
                          width: 10,
                        },
                      ]}
                    />
                  )}
                  {/* {!recipe.id && (
                      <Modal onClose={() => {}}>
                        <ViewRecipes />
                      </Modal>
                    )} */}
                </div>
              ))}

              <div className={CSS.tableButton}>
                <Button
                  onClick={() => arrayHelpers.push({})}
                  mode="primary"
                  label="+"
                />
              </div>
            </div>
          )}
        />
      </CollapsableDiv>
      <span className={CSS.formField}>
        <LabelText text="Ingredients" />
        <Select
          isMulti
          onChange={(value) => {
            setSelectedTags(value as IngredientType[]);
            setFieldValue(
              "lunch.ingredients",
              (value as IngredientType[]).map((ingredient) => ingredient.value)
            );
          }}
          options={ingredientsData}
          styles={darkThemeStyles}
          value={selectedTags.length > 0 ? selectedTags : undefined}
        />
      </span>
    </>
  );
};
