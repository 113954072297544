import { ReportType } from "../../types/reports";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";
import { ReportToPDF } from "./pdf-helpers";
import { pdf } from "@react-pdf/renderer";

export const generatePDFBlob = async (
  report: ReportType,
  template: "Template1"
) => {
  // Create the PDF document as JSX
  const pdfDoc = <ReportToPDF report={report} template={template} />;

  // Convert the document to a Blob
  const blob = await pdf(pdfDoc).toBlob();

  return blob;
};

export const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const sendEmail = async (report: ReportType) => {
  const pdfBlob = await generatePDFBlob(report, "Template1");

  // Convert Blob to base64 and remove the prefix
  const pdfBase64 = (await blobToBase64(pdfBlob)).split(",")[1];

  const emailData = {
    to_email: report.patient.email || "",
    to_name: report.patient.name || "Recipient",
    message: "Please find the attached report.",
    attached_file: pdfBase64, // Make sure this matches the field in your EmailJS template
  };

  const htmlEmailTemplate = "template_wc56l13";
  const normalEmailTemplate = "template_wx8s5pt";

  const templateId = false ? htmlEmailTemplate : normalEmailTemplate;

  emailjs
    .send("service_h1ildtf", templateId, emailData, "BpAvvDMIPBILvFYtD")
    .then((response) => {
      toast.success("Email sent successfully to " + emailData.to_email);
    })
    .catch((err) => {
      toast.error("Failed to send email to " + emailData.to_email);
      console.error("Failed to send email:", err);
    });
};
