import React from "react";

import "./App.css";
//import { Login } from "./features/login";
import { Dashboard } from "./features/dashboard";
import { DashboardProvider } from "./features/dashboard/dashboard-context";
import { Toast } from "./features/common/components/toast/toast";
import { AppProvider } from "./features/app-context/app-context";

export function App() {
  return (
    <div className="App">
      <AppProvider>
        <DashboardProvider>
          <Toast />
          <Dashboard />
        </DashboardProvider>
      </AppProvider>
    </div>
  );
}
