import { ReportType } from "../types/reports";

export const sampleReports: ReportType[] = [
  {
    id: "1",
    patient: {
      id: "10",
      name: "Henry Clark",
      age: 37,
      bloodType: "B",
      weight: 78,
      phoneNumber: "1234567899",
      email: "henry.clark@gmail.com",
      address: "707 Chestnut St",
      referredBy: "Dr. Violet",
      patientSince: new Date("2011-06-05"),
      visits: [],
    },
    avoids: {
      meat: [],
      seafood: [],
      vegetablesAndFruits: ["banana", "orange"],
      others: ["sugar"],
    },
    breakfast: {
      recipes: [
        {
          duration: 10,
          id: "1",
          name: "Banana and Apple Smoothie",
          ingredients: [
            { ingredientName: "Banana", quantity: 1 },
            { ingredientName: "Apples", quantity: 1, unit: "kg" },
            { ingredientName: "Yogurt", quantity: 0.5, unit: "l" },
            { ingredientName: "Banana", quantity: 1 },
            { ingredientName: "Apples", quantity: 1, unit: "kg" },
            { ingredientName: "Yogurt", quantity: 0.5, unit: "l" },
          ],
          instructions: "Blend all the ingredients together and serve chilled.",
          dateCreated: "2021-09-01",
          tags: ["Lack of Good Fats", "Lack of Vitamins"],
        },
      ],
      instructions: [
        {
          title: "Breakfast",
          details: "1 cup of oatmeal with 1 cup of milk and 1 apple",
          duration: 10,
        },
      ],
      ingredients: ["oatmeal", "milk", "apple"],
    },
    lunch: {
      recipes: [
        {
          duration: 5,
          id: "1",
          name: "Banana and Apple Smoothie",
          ingredients: [
            { ingredientName: "Banana", quantity: 1 },
            { ingredientName: "Apples", quantity: 1, unit: "kg" },
            { ingredientName: "Yogurt", quantity: 0.5, unit: "l" },
            { ingredientName: "Banana", quantity: 1 },
            { ingredientName: "Apples", quantity: 1, unit: "kg" },
            { ingredientName: "Yogurt", quantity: 0.5, unit: "l" },
          ],
          instructions: "Blend all the ingredients together and serve chilled.",
          dateCreated: "2021-09-01",
          tags: ["Lack of Good Fats", "Lack of Vitamins"],
        },
        {
          duration: 33,
          id: "2",
          name: "Pumpkin Soup",
          ingredients: [
            { ingredientName: "Pumpkin", quantity: 1, unit: "kg" },
            { ingredientName: "Onion", quantity: 1 },
            { ingredientName: "Garlic", quantity: 2, unit: "quantity" },
          ],
          instructions:
            "Boil the pumpkin, onion, and garlic together. Blend the mixture and serve hot.",
          dateCreated: "2021-09-02",
          tags: ["Immune System", "Fermentation"],
        },
        {
          duration: 15,
          id: "3",
          name: "Pasta with Tomato Sauce",
          ingredients: [
            { ingredientName: "Pasta", quantity: 500, unit: "g" },
            { ingredientName: "Tomatoes", quantity: 4 },
            { ingredientName: "Garlic", quantity: 2, unit: "quantity" },
          ],
          instructions:
            "Boil the pasta. Cook the tomatoes and garlic together to make the sauce. Serve hot.",
          dateCreated: "2021-09-03",
          tags: ["Dehydration", "Liver", "Cholesterol"],
        },
      ],
      instructions: [
        {
          title: "Lunch",
          details: "1 cup of rice with 1 cup of lentils and 1 orange",
          duration: 10,
        },
      ],
      ingredients: ["rice", "lentils", "orange"],
    },
    dinner: {
      recipes: [],
      instructions: [
        {
          title: "Dinner",
          details: "1 cup of rice with 1 cup of lentils and 1 orange",
          duration: 10,
        },
      ],
      ingredients: ["rice", "lentils", "orange"],
    },
    diagnosis: [
      {
        diagnosisName: "Lack of Good Fats",
        intensity: 3,
        remarks: "Needs to consume more nuts and seeds.",
      },
      {
        diagnosisName: "Lack of Vitamins",
        intensity: 2,
        remarks: "Needs to consume more fruits and vegetables.",
      },
    ],
  },
  {
    id: "2",
    diagnosis: [
      {
        diagnosisName: "Lack of Good Fats",
        intensity: 3,
        remarks: "Needs to consume more nuts and seeds.",
      },
      {
        diagnosisName: "Lack of Vitamins",
        intensity: 2,
        remarks: "Needs to consume more fruits and vegetables.",
      },
    ],

    patient: {
      id: "9",
      name: "Grace Lee",
      age: 29,
      bloodType: "A",
      weight: 72,
      phoneNumber: "1234567898",
      email: "",
      address: "606 Walnut St",
      referredBy: "Dr. Red",
      patientSince: new Date("2012-10-30"),
      visits: [],
    },
    avoids: {
      meat: ["beef", "chicken"],
      seafood: ["prawns"],
      vegetablesAndFruits: ["banana", "apple"],
      others: ["sugar"],
    },
    breakfast: {
      recipes: [
        {
          duration: 10,
          id: "1",
          name: "Banana and Apple Smoothie",
          ingredients: [
            { ingredientName: "Banana", quantity: 1 },
            { ingredientName: "Apples", quantity: 1, unit: "kg" },
            { ingredientName: "Yogurt", quantity: 0.5, unit: "l" },
            { ingredientName: "Banana", quantity: 1 },
            { ingredientName: "Apples", quantity: 1, unit: "kg" },
            { ingredientName: "Yogurt", quantity: 0.5, unit: "l" },
          ],
          instructions: "Blend all the ingredients together and serve chilled.",
          dateCreated: "2021-09-01",
          tags: ["Lack of Good Fats", "Lack of Vitamins"],
        },
      ],
      instructions: [
        {
          title: "Breakfast",
          details: "1 cup of oatmeal with 1 cup of milk and 1 apple",
          duration: 10,
        },
      ],
      ingredients: ["oatmeal", "milk", "apple"],
    },
    lunch: {
      recipes: [
        {
          duration: 20,
          id: "1",
          name: "Banana and Apple Smoothie",
          ingredients: [
            { ingredientName: "Banana", quantity: 1 },
            { ingredientName: "Apples", quantity: 1, unit: "kg" },
            { ingredientName: "Yogurt", quantity: 0.5, unit: "l" },
            { ingredientName: "Banana", quantity: 1 },
            { ingredientName: "Apples", quantity: 1, unit: "kg" },
            { ingredientName: "Yogurt", quantity: 0.5, unit: "l" },
          ],
          instructions: "Blend all the ingredients together and serve chilled.",
          dateCreated: "2021-09-01",
          tags: ["Lack of Good Fats", "Lack of Vitamins"],
        },
      ],
      instructions: [
        {
          title: "Lunch",
          details: "1 cup of rice with 1 cup of lentils and 1 orange",
          duration: 10,
        },
      ],
      ingredients: ["rice", "lentils", "orange"],
    },
    dinner: {
      recipes: [],
      instructions: [
        {
          title: "Dinner",
          details: "1 cup of rice with 1 cup of lentils and 1 orange",
          duration: 10,
        },
      ],
      ingredients: ["rice", "lentils", "orange"],
    },
  },
];
