import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { ReportType } from "../../types/reports";
import { IngredientUsage } from "../../types/recipes";

const styles = StyleSheet.create({
  page: {
    fontSize: 12,
    fontFamily: "Helvetica",
    padding: 20,
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  section: {
    marginBottom: 20,
    padding: 10,
    border: "1px dotted #ccc",
    borderRadius: 5,
    backgroundColor: "#f9f9f9",
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 8,
    borderBottom: "2px solid #333",
    paddingBottom: 4,
  },
  subtitle: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 4,
  },
  personalInfoTable: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
  },
  personalInfoRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  personalInfoItem: {
    flex: 1, // Added this to ensure each item takes equal space
    flexDirection: "row",
  },
  personalInfoSection: {
    backgroundColor: "#f9f9f9",
    padding: 10,
  },
  labelText: {
    fontWeight: "normal",
  },
  boldText: {
    fontSize: 12,
  },
  listItem: {
    marginLeft: 10,
    marginBottom: 2,
  },
  emptySection: {
    marginTop: 10,
    fontStyle: "italic",
    color: "gray",
  },
  header: {
    fontSize: 24,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 20,
  },
  subheader: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 10,
    borderBottom: "1px solid #333",
    paddingBottom: 4,
  },
  disclaimer: {
    fontSize: 8,
    marginTop: 10,
    textAlign: "center",
  },
  copyright: {
    fontSize: 10,
    fontStyle: "italic",
    color: "grey",
    textAlign: "center",
    marginTop: 10,
  },
  table: {
    display: "flex",
    flexDirection: "column",
    borderWidth: 1,
    borderColor: "black",
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "black",
  },
  tableCell: {
    flex: 1,
    padding: 5,
  },
  tableHeaderCell: {
    flex: 1,
    padding: 5,
    fontWeight: "bold",
  },
  nestedTable: {
    marginLeft: 10,
    borderWidth: 1.5,
    borderColor: "black",
    borderRadius: 2,
  },
});

type TemplateType = "Template1" | "Template2";

export const ReportToPDF = ({
  report,
  template,
}: {
  report: ReportType;
  template: TemplateType;
}) => {
  return template === "Template1" ? (
    <Template1 report={report} />
  ) : (
    <Template2 report={report} />
  );
};

// Template 1 Component
const Template1 = ({ report }: { report: ReportType }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Text style={styles.header}>Report</Text>

      <View style={styles.personalInfoSection}>
        <View style={styles.personalInfoTable}>
          <View style={styles.personalInfoRow}>
            <View style={styles.personalInfoItem}>
              <Text style={styles.labelText}>Name: </Text>
              <Text style={styles.boldText}>{report.patient.name}</Text>
            </View>
            <View style={styles.personalInfoItem}>
              <Text style={styles.labelText}>Age: </Text>
              <Text style={styles.boldText}>{report.patient.age}</Text>
            </View>
          </View>

          <View style={styles.personalInfoRow}>
            <View style={styles.personalInfoItem}>
              <Text style={styles.labelText}>Blood Type: </Text>
              <Text style={styles.boldText}>{report.patient.bloodType}</Text>
            </View>
            <View style={styles.personalInfoItem}>
              <Text style={styles.labelText}>Address: </Text>
              <Text style={styles.boldText}>{report.patient.address}</Text>
            </View>
          </View>

          <View style={styles.personalInfoRow}>
            <View style={styles.personalInfoItem}>
              <Text style={styles.labelText}>Referred By: </Text>
              <Text style={styles.boldText}>{report.patient.referredBy}</Text>
            </View>
            <View style={styles.personalInfoItem}>
              <Text style={styles.labelText}>Date: </Text>
              <Text style={styles.boldText}>
                {new Date().toLocaleDateString()}
              </Text>
            </View>
          </View>
        </View>
      </View>

      <Section title="Foods to Avoid">
        <AvoidedFoods avoids={report.avoids} />
      </Section>

      <View>
        <Text style={styles.subheader}>Meal Plans</Text>
        {["breakfast", "lunch", "dinner"].map((meal) => (
          <MealSection
            key={meal}
            meal={meal}
            data={report[meal as "breakfast" | "lunch" | "dinner"]}
          />
        ))}
      </View>

      <AdditionalSections
        titles={["Drinks", "Supplements", "General Instructions"]}
      />

      <Text style={styles.disclaimer}>
        Disclaimer: These reports have not been evaluated by the FDA. These
        products are NOT intended to diagnose, treat, cure or prevent any
        disease.
      </Text>
      <Text style={styles.copyright}>
        © 2024 Weldy Wellness, LLC. All Rights Reserved.
      </Text>
    </Page>
  </Document>
);

// Reusable Section Component
const Section = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => (
  <View style={styles.section}>
    <Text style={styles.title}>{title}</Text>
    {children}
  </View>
);

// Foods to Avoid Section
const AvoidedFoods = ({ avoids }: { avoids: ReportType["avoids"] }) =>
  avoids ? (
    <>
      {avoids.meat.length > 0 && (
        <Text style={styles.listItem}>Meat: {avoids.meat.join(", ")}</Text>
      )}
      {avoids.seafood.length > 0 && (
        <Text style={styles.listItem}>
          Seafood: {avoids.seafood.join(", ")}
        </Text>
      )}
      {avoids.vegetablesAndFruits.length > 0 && (
        <Text style={styles.listItem}>
          Vegetables & Fruits: {avoids.vegetablesAndFruits.join(", ")}
        </Text>
      )}
      {avoids.others.length > 0 && (
        <Text style={styles.listItem}>Others: {avoids.others.join(", ")}</Text>
      )}
    </>
  ) : (
    <Text style={styles.emptySection}>[No foods to avoid]</Text>
  );

// Meal Section Component
const MealSection = ({
  meal,
  data,
}: {
  meal: string;
  data?: ReportType["breakfast"] | ReportType["lunch"] | ReportType["dinner"];
}) => (
  <View style={styles.section}>
    <Text style={styles.title}>
      {meal.charAt(0).toUpperCase() + meal.slice(1)}
    </Text>

    {data?.instructions && (
      <>
        <Text style={styles.subtitle}>Instructions</Text>
        <View style={[styles.table, styles.nestedTable]}>
          {data.instructions.map((instruction, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={styles.tableHeaderCell}>{instruction.title}</Text>
              <Text style={styles.tableCell}>{instruction.details}</Text>
              {instruction.duration && (
                <Text style={styles.tableCell}>
                  {instruction.duration} days
                </Text>
              )}
            </View>
          ))}
        </View>
      </>
    )}

    {data?.ingredients && (
      <>
        <Text style={styles.subtitle}>Ingredients</Text>
        <View style={[styles.table, styles.nestedTable]}>
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}>{data.ingredients.join(", ")}</Text>
          </View>
        </View>
      </>
    )}

    <Text style={styles.subtitle}>Recipes</Text>
    {data?.recipes?.map((recipe, index) => (
      <View key={index} style={[styles.table, styles.nestedTable]}>
        {/* Recipe Name Row */}
        <View style={styles.tableRow}>
          <Text
            style={[styles.tableCell, { fontWeight: "bold", fontSize: 14 }]}
          >
            {recipe.name}
          </Text>
        </View>

        {/* Duration Row */}
        <View style={styles.tableRow}>
          <Text style={styles.tableHeaderCell}>Duration:</Text>
          <Text style={styles.tableCell}>{recipe.duration} days</Text>
        </View>

        {/* Instructions Row */}
        <View style={styles.tableRow}>
          <Text style={styles.tableHeaderCell}>Instructions:</Text>
          <Text style={styles.tableCell}>{recipe.instructions}</Text>
        </View>

        {/* Ingredients Row */}
        <View style={styles.tableRow}>
          <Text style={styles.tableHeaderCell}>Ingredients:</Text>
          <View style={styles.tableCell}>
            {recipe.ingredients.map((ing, ingIndex) => (
              <Text key={ingIndex} style={styles.listItem}>
                • {RecipeIngredientRowToString(ing)}
              </Text>
            ))}
          </View>
        </View>
      </View>
    ))}
  </View>
);

// Additional Sections Component
const AdditionalSections = ({ titles }: { titles: string[] }) => (
  <>
    {titles.map((title) => (
      <View style={styles.section} key={title}>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.emptySection}>[No information available]</Text>
      </View>
    ))}
  </>
);

const Template2 = ({ report }: { report: ReportType }) => <div>Template 2</div>;

function RecipeIngredientRowToString(ing: IngredientUsage) {
  return `${ing.ingredientName} ${ing.quantity} ${ing.unit ?? ""} ${
    ing.description ?? ""
  }`;
}
