import React, { useContext, useState } from "react";
import CSS from "./patients.module.css";
import { DashboardContext } from "../../dashboard/dashboard-context";
import Button from "../../common/components/button";
import { AppContext } from "../../app-context/app-context";
import { Pen } from "lucide-react";
import { IconButton } from "../../common/components/icon-button";
import { ReportsContext } from "../reports/reports-context";
import { AddReport } from "../reports/pages/sections";
export const PatientDetails = () => {
  const {
    editPatient,
    setActiveSection,
    setEditPatient,
    setEditReport,
    sendEmail,
  } = useContext(DashboardContext);

  const { allReports } = useContext(AppContext);

  const { showModal, setShowModal } = useContext(ReportsContext);

  const reportsByPatient = allReports.filter(
    (report) => report.patient.id === editPatient?.id
  );

  if (!editPatient) {
    return null;
  }

  return (
    <>
      <div className={CSS.container}>
        <div className={CSS.header}>
          <div className={CSS.gridContainer}>
            <label>
              <b>Name: </b>
              {editPatient.name}
            </label>
            <label>
              <b>Age: </b>
              {editPatient.age}
            </label>
            <label>
              <b>Patient since: </b>
              {editPatient.patientSince + ""}
            </label>

            <label>
              <b>Weight: </b>
              {editPatient.weight ?? "N/A"}
            </label>
            <label>
              <b>Referred by: </b>
              {editPatient.referredBy ?? "N/A"}
            </label>
            <label>
              <b>Blood group: </b>
              {editPatient.bloodType}
            </label>

            <label>
              <b>Email: </b>
              {editPatient.email ?? "N/A"}
            </label>
            <label>
              <b>Phone: </b>
              {editPatient.phoneNumber ?? "N/A"}
            </label>
            <label>
              <b>Address: </b>
              {editPatient.address ?? "N/A"}
            </label>
          </div>
          <div className={CSS.editPatientButton}>
            <IconButton
              color="black"
              text="Edit Client"
              icon={<Pen color="black" size={18} />}
              onClick={() => {
                setActiveSection("add-client");
                setEditPatient(editPatient);
              }}
            />
          </div>
        </div>

        <div className={CSS.history}>
          <h2>History</h2>
          <table>
            <thead>
              <tr>
                <th>Sr.</th>
                <th>Report ID</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>
              {reportsByPatient.map((report, index) => (
                <tr
                  key={report.id}
                  onClick={() => {
                    setEditReport(report);
                    setShowModal(true);
                  }}
                >
                  <td>{index + 1}</td>
                  <td>{report.id}</td>
                  <td>{report.notes}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className={CSS.buttonsRow}>
          <Button
            label="Back"
            onClick={() => {
              setActiveSection("Client Records");
              setEditPatient(undefined);
            }}
          />
        </div>
      </div>
      {showModal && <AddReport />}
    </>
  );
};
