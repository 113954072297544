import React from "react";
import css from "./home.module.css";
import { AlbumIcon, TabletsIcon, UserIcon, VeganIcon } from "lucide-react";
export const Home = () => {
  return (
    <div className={css.container}>
      <div className={css.homeButtons}>
        <span className={css.buttonTile}>
          <UserIcon color="black" size={36} /> Add Client
        </span>
        <span className={css.buttonTile}>
          <VeganIcon color="black" size={36} />
          Add Recipe
        </span>
        <span className={css.buttonTile}>
          <AlbumIcon color="black" size={36} />
          Add Report
        </span>
        <span className={css.buttonTile}>
          <TabletsIcon color="black" size={36} />
          Supplements
        </span>
      </div>
      <div className={css.homeTable}>TODO: Display something here</div>
    </div>
  );
};
