import React, { ReactNode } from "react";
import { LabelText } from "..";
import CSS from "./table.module.css";

export const Table = ({
  children,
  height = 100,
  headerRow,
}: {
  children: ReactNode;
  height?: number;
  headerRow?: ColumnData[];
}) => {
  return (
    <div style={{ maxHeight: `${height}%` }} className={CSS.table}>
      <li className={CSS.listRow} data-header={true}>
        {headerRow &&
          headerRow.map((column, index) => (
            <span
              key={index}
              style={{ width: column.width ? `${column.width}%` : "auto" }}
            >
              {column.data && <LabelText text={column.data + ""} />}
              {column.child}
            </span>
          ))}
      </li>
      <div className={CSS.tableBody}>{children}</div>
    </div>
  );
};

// Todo: Add proper border for the table

type ColumnData = {
  width?: number;
  data?: string | number;
  child?: ReactNode;
};

type TableRowProps = {
  columns: ColumnData[];
  minHeight?: number;
};
export const TableRow = ({ columns, minHeight }: TableRowProps) => {
  return (
    <li style={{ minHeight: minHeight ?? 0 }} className={CSS.listRow}>
      {columns.map((column, index) => (
        <span
          key={index}
          style={{ width: column.width ? `${column.width}%` : "auto" }}
        >
          {column.data && <LabelText text={column.data + ""} />}
          {column.child}
        </span>
      ))}
    </li>
  );
};
