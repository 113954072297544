import React from "react";
import CSS from "./icon-button.module.css";
type IconButtonProps = {
  icon: React.ReactNode;
  text?: string;
  onClick: () => void;
  iconDirection?: "left" | "right";
  color?: string;
};

export const IconButton = ({
  iconDirection = "left",
  text,
  icon,
  onClick,
  color = "white",
}: IconButtonProps) => {
  return (
    <span
      data-direction={iconDirection === "right"}
      className={CSS.buttonContainer}
      onClick={onClick}
    >
      <span>{icon}</span>
      {text && <span style={{ color: color }}>{text}</span>}
    </span>
  );
};
