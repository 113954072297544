import { Recipe } from "../types/recipes";

export const ingredientsData = [
  // Fruits
  { value: "lemon", label: "Lemon - FRUIT" },
  { value: "lime", label: "Lime - FRUIT" },
  { value: "banana", label: "Banana - FRUIT" },
  { value: "apple", label: "Apple - FRUIT" },
  { value: "orange", label: "Orange - FRUIT" },
  { value: "strawberry", label: "Strawberry - FRUIT" },
  { value: "grape", label: "Grape - FRUIT" },
  { value: "mango", label: "Mango - FRUIT" },
  { value: "pineapple", label: "Pineapple - FRUIT" },
  { value: "blueberry", label: "Blueberry - FRUIT" },
  { value: "watermelon", label: "Watermelon - FRUIT" },
  { value: "cherry", label: "Cherry - FRUIT" },
  { value: "avocado", label: "Avocado - FRUIT" },
  { value: "papaya", label: "Papaya - FRUIT" },
  { value: "kiwi", label: "Kiwi - FRUIT" },
  { value: "pomegranate", label: "Pomegranate - FRUIT" },
  { value: "peach", label: "Peach - FRUIT" },
  { value: "plum", label: "Plum - FRUIT" },
  { value: "pear", label: "Pear - FRUIT" },
  { value: "fig", label: "Fig - FRUIT" },
  { value: "guava", label: "Guava - FRUIT" },
  { value: "lychee", label: "Lychee - FRUIT" },
  { value: "raspberry", label: "Raspberry - FRUIT" },
  { value: "blackberry", label: "Blackberry - FRUIT" },
  { value: "grapefruit", label: "Grapefruit - FRUIT" },
  { value: "cantaloupe", label: "Cantaloupe - FRUIT" },
  { value: "honeydew", label: "Honeydew - FRUIT" },
  { value: "dragonfruit", label: "Dragonfruit - FRUIT" },
  { value: "starfruit", label: "Starfruit - FRUIT" },

  // Meats
  { value: "chicken", label: "Chicken - MEAT" },
  { value: "beef", label: "Beef - MEAT" },
  { value: "pork", label: "Pork - MEAT" },
  { value: "lamb", label: "Lamb - MEAT" },
  { value: "turkey", label: "Turkey - MEAT" },
  { value: "duck", label: "Duck - MEAT" },
  { value: "goat", label: "Goat - MEAT" },
  { value: "bison", label: "Bison - MEAT" },
  { value: "venison", label: "Venison - MEAT" },
  { value: "rabbit", label: "Rabbit - MEAT" },

  // Seafood
  { value: "salmon", label: "Salmon - SEAFOOD" },
  { value: "shrimp", label: "Shrimp - SEAFOOD" },
  { value: "tuna", label: "Tuna - SEAFOOD" },
  { value: "crab", label: "Crab - SEAFOOD" },
  { value: "lobster", label: "Lobster - SEAFOOD" },
  { value: "octopus", label: "Octopus - SEAFOOD" },
  { value: "mussels", label: "Mussels - SEAFOOD" },
  { value: "clams", label: "Clams - SEAFOOD" },
  { value: "squid", label: "Squid - SEAFOOD" },
  { value: "oyster", label: "Oyster - SEAFOOD" },

  // Vegetables
  { value: "carrot", label: "Carrot - VEGETABLE" },
  { value: "broccoli", label: "Broccoli - VEGETABLE" },
  { value: "spinach", label: "Spinach - VEGETABLE" },
  { value: "potato", label: "Potato - VEGETABLE" },
  { value: "tomato", label: "Tomato - VEGETABLE" },
  { value: "onion", label: "Onion - VEGETABLE" },
  { value: "cucumber", label: "Cucumber - VEGETABLE" },
  { value: "pepper", label: "Pepper - VEGETABLE" },
  { value: "lettuce", label: "Lettuce - VEGETABLE" },
  { value: "cabbage", label: "Cabbage - VEGETABLE" },
  { value: "zucchini", label: "Zucchini - VEGETABLE" },
  { value: "cauliflower", label: "Cauliflower - VEGETABLE" },
  { value: "eggplant", label: "Eggplant - VEGETABLE" },
  { value: "beetroot", label: "Beetroot - VEGETABLE" },
  { value: "kale", label: "Kale - VEGETABLE" },
  { value: "asparagus", label: "Asparagus - VEGETABLE" },
  { value: "celery", label: "Celery - VEGETABLE" },
  { value: "sweet_potato", label: "Sweet Potato - VEGETABLE" },
  { value: "radish", label: "Radish - VEGETABLE" },
  { value: "brussels_sprout", label: "Brussels Sprout - VEGETABLE" },
  { value: "artichoke", label: "Artichoke - VEGETABLE" },
  { value: "parsnip", label: "Parsnip - VEGETABLE" },
  { value: "leek", label: "Leek - VEGETABLE" },
  { value: "swiss_chard", label: "Swiss Chard - VEGETABLE" },

  // Nuts
  { value: "almond", label: "Almond - NUT" },
  { value: "walnut", label: "Walnut - NUT" },
  { value: "cashew", label: "Cashew - NUT" },
  { value: "pecan", label: "Pecan - NUT" },
  { value: "pistachio", label: "Pistachio - NUT" },
  { value: "hazelnut", label: "Hazelnut - NUT" },
  { value: "macadamia", label: "Macadamia - NUT" },
  { value: "brazilnut", label: "Brazil Nut - NUT" },
  { value: "pine_nut", label: "Pine Nut - NUT" },
  { value: "chestnut", label: "Chestnut - NUT" },

  // Seeds
  { value: "chia", label: "Chia - SEED" },
  { value: "flax", label: "Flax - SEED" },
  { value: "pumpkin", label: "Pumpkin - SEED" },
  { value: "sunflower", label: "Sunflower - SEED" },
  { value: "sesame", label: "Sesame - SEED" },
  { value: "hemp", label: "Hemp - SEED" },
  { value: "poppy", label: "Poppy - SEED" },
  { value: "quinoa", label: "Quinoa - SEED" },
  { value: "mustard", label: "Mustard - SEED" },
  { value: "caraway", label: "Caraway - SEED" },

  // Powders
  { value: "turmeric", label: "Turmeric - POWDER" },
  { value: "ginger", label: "Ginger - POWDER" },
  { value: "cinnamon", label: "Cinnamon - POWDER" },
  { value: "garlic", label: "Garlic - POWDER" },
  { value: "matcha", label: "Matcha - POWDER" },
  { value: "ashwagandha", label: "Ashwagandha - POWDER" },
  { value: "maca", label: "Maca - POWDER" },
  { value: "spirulina", label: "Spirulina - POWDER" },
  { value: "acai", label: "Acai - POWDER" },
  { value: "camu_camu", label: "Camu Camu - POWDER" },
  { value: "moringa", label: "Moringa - POWDER" },
  { value: "beetroot_powder", label: "Beetroot Powder - POWDER" },
  { value: "wheatgrass", label: "Wheatgrass - POWDER" },
  { value: "barley_grass", label: "Barley Grass - POWDER" },
  { value: "alfalfa", label: "Alfalfa - POWDER" },
  { value: "chlorella", label: "Chlorella - POWDER" },
  { value: "kelp", label: "Kelp - POWDER" },
  { value: "dulse", label: "Dulse - POWDER" },
  { value: "nutritional_yeast", label: "Nutritional Yeast - POWDER" },
  { value: "salt", label: "Salt - POWDER" },
  { value: "pepper", label: "Pepper - POWDER" },
  { value: "cayenne", label: "Cayenne - POWDER" },
  { value: "paprika", label: "Paprika - POWDER" },
  { value: "cumin", label: "Cumin - POWDER" },
  { value: "red_pepper", label: "Red Pepper - POWDER" },
  { value: "black_pepper", label: "Black Pepper - POWDER" },
  { value: "white_pepper", label: "White Pepper - POWDER" },
  { value: "coriander", label: "Coriander - POWDER" },

  // Oils
  { value: "olive_oil", label: "Olive Oil - OIL" },
  { value: "coconut_oil", label: "Coconut Oil - OIL" },
  { value: "almond_oil", label: "Almond Oil - OIL" },
  { value: "flaxseed_oil", label: "Flaxseed Oil - OIL" },
  { value: "hemp_oil", label: "Hemp Oil - OIL" },
  { value: "sesame_oil", label: "Sesame Oil - OIL" },
  { value: "black_seed_oil", label: "Black Seed Oil - OIL" },
  { value: "pumpkin_seed_oil", label: "Pumpkin Seed Oil - OIL" },
  { value: "walnut_oil", label: "Walnut Oil - OIL" },
  { value: "avocado_oil", label: "Avocado Oil - OIL" },
];

export const RecipesData: Recipe[] = [
  {
    id: "0",
    name: "TEST RECIPE: Turmeric / Olive oil paste",
    ingredients: [
      { ingredientName: "Turmeric", quantity: 1, unit: "tbsp" },
      { ingredientName: "Olive oil", quantity: 1, unit: "tbsp" },
      { ingredientName: "Lemon", quantity: 1, unit: "tbsp" },
      { ingredientName: "cayenne", quantity: 1, unit: "tbsp" },
    ],
    instructions:
      "Mix the powdered spices with lemon juice, and then add olive oil until it becomes a fine paste. Not too thin",
    dateCreated: "2021-09-01",
    tags: ["Immune System", "Digestion"],
  },
  {
    id: "1",
    name: "TEST RECIPE: Ginger / Honey paste",
    ingredients: [
      { ingredientName: "Ginger", quantity: 1, unit: "tbsp" },
      { ingredientName: "Honey", quantity: 1, unit: "tbsp" },
      { ingredientName: "Lemon", quantity: 1, unit: "tbsp" },
    ],
    instructions:
      "Mix the powdered spices with lemon juice, and then add honey until it becomes a fine paste. Not too thin",
    dateCreated: "2021-09-01",
    tags: ["Immune System", "Iron"],
  },
  {
    id: "2",
    name: "TEST RECIPE: Cinnamon / Honey paste",
    ingredients: [
      { ingredientName: "Cinnamon", quantity: 1, unit: "tbsp" },
      { ingredientName: "Honey", quantity: 1, unit: "tbsp" },
      { ingredientName: "Lemon", quantity: 1, unit: "tbsp" },
    ],
    instructions:
      "Mix the powdered spices with lemon juice, and then add honey until it becomes a fine paste. Not too thin",
    dateCreated: "2021-09-01",
    tags: ["Immune System", "Dehydration"],
  },
  {
    id: "3",
    name: "TEST RECIPE: Garlic / Honey paste",
    ingredients: [
      { ingredientName: "Garlic", quantity: 1, unit: "tbsp" },
      { ingredientName: "Honey", quantity: 1, unit: "tbsp" },
      { ingredientName: "Lemon", quantity: 1, unit: "tbsp" },
    ],
    instructions:
      "Mix the powdered spices with lemon juice, and then add honey until it becomes a fine paste. Not too thin",
    dateCreated: "2021-09-01",
    tags: ["Immune System", "D3"],
  } as Recipe,
  {
    id: "4",
    name: "TEST RECIPE: Warm Sesseme Seeds",
    ingredients: [
      { ingredientName: "Sesame Seeds", quantity: 1, unit: "tbsp" },
    ],
    instructions:
      "Roast the sesame seeds in a pan until they are warm to the touch. Do not burn them.",
    dateCreated: "2021-09-01",
    tags: ["Immune System", "Minerals"],
  },
  {
    id: "5",
    name: "TEST RECIPE: Carrot / Beetroot Juice",
    ingredients: [
      { ingredientName: "Carrot", quantity: 2, description: "1-2 carrots" },
      { ingredientName: "Beetroot", quantity: 1 },
      { ingredientName: "Lemon", quantity: 1, unit: "tbsp" },
      { ingredientName: "Salt", quantity: 1, unit: "pinch" },
      { ingredientName: "Ginger", quantity: 1, description: "1 inch piece" },
      { ingredientName: "Spinach", quantity: 1, description: "Handful" },
    ],

    instructions:
      "Juice the carrots, beetroot, ginger, and spinach. Add lemon juice and a pinch of salt. Mix well and serve chilled.",
    dateCreated: "2021-09-01",
    tags: ["Immune System", "Hormones"],
  },
];
