import { sampleReports } from "../data/sample-reports";
import { ReportType } from "../types/reports";

const STORAGE_KEY = "reports";

function initializeReports() {
  const storedReports = localStorage.getItem(STORAGE_KEY);
  if (!storedReports) {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(sampleReports));
  }
}

export function GetAllReports(): ReportType[] {
  initializeReports();
  const storedReports = localStorage.getItem(STORAGE_KEY);
  return storedReports ? JSON.parse(storedReports) : [];
}

export function AddReport(newReport: ReportType): void {
  const reports = GetAllReports();
  reports.push(newReport);
  localStorage.setItem(STORAGE_KEY, JSON.stringify(reports));
}
export function GetReportById(reportId: string): ReportType | undefined {
  const reports = GetAllReports();
  return reports.find((report) => report.id === reportId);
}

export function UpdateReport(updatedReport: ReportType): void {
  const reports = GetAllReports();
  const index = reports.findIndex((report) => report.id === updatedReport.id);
  reports[index] = updatedReport;
  localStorage.setItem(STORAGE_KEY, JSON.stringify(reports));
}

export function DeleteReport(reportId: string): void {
  const reports = GetAllReports();
  const index = reports.findIndex((report) => report.id === reportId);
  reports.splice(index, 1);
  localStorage.setItem(STORAGE_KEY, JSON.stringify(reports));
}
