import React, { createContext, useState, ReactNode, FC } from "react";
import { Recipe } from "../../types/recipes";
import { Patient } from "../../types/patients";
import { ReportType } from "../../types/reports";
import { GetAllPatients } from "../../api/patients-api";
import { GetAllReports } from "../../api/reports-api";
import { GetAllRecipes } from "../../api/recipes-api";

type AppContextType = {
  allPatients: Patient[];
  setAllPatients: (patients: Patient[]) => void;
  allRecipes: Recipe[];
  setAllRecipes: (recipes: Recipe[]) => void;
  allReports: ReportType[];
  setAllReports: (reports: ReportType[]) => void;
};

const initialContext: AppContextType = {
  allPatients: [],
  setAllPatients: () => {},
  allRecipes: [],
  setAllRecipes: () => {},
  allReports: [],
  setAllReports: () => {},
};

export const AppContext = createContext<AppContextType>(initialContext);

/*
 * This is the provider for the AppContext
 * It is used to wrap the entire application in the App component
 * and pass down the global data
 */
export const AppProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [allPatients, setAllPatients] = React.useState<Patient[]>(
    GetAllPatients()
  );
  const [allRecipes, setAllRecipes] = useState<Recipe[]>(GetAllRecipes());
  const [allReports, setAllReports] = useState<ReportType[]>(GetAllReports());

  return (
    <AppContext.Provider
      value={{
        allPatients,
        setAllPatients,
        allRecipes,
        setAllRecipes,
        allReports,
        setAllReports,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
