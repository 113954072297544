import React, { useContext, useState } from "react";
import Select from "react-select";
import { ingredientsData } from "../../../data/sample-data";
import { Dropdown, InputField, LabelText } from "../../common/components";
import Button from "../../common/components/button";
import CSS from "./recipes.module.css";
import { Field, Form, Formik } from "formik";
import { Recipe, RecipeTagsOptions, Units } from "../../../types/recipes";
import { DashboardContext } from "../../dashboard/dashboard-context";

import {
  AddRecipe as AddRecipeAPI,
  UpdateRecipe,
} from "../../../api/recipes-api";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

const darkThemeStyles = {
  control: (provided: any) => ({
    ...provided,
    backgroundColor: "#333", // Dark background
    color: "#172B4D", // Text color
    borderColor: "#555", // Lighter border color
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: "#333", // Dark background for the dropdown
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#555" : "#333", // Different background for selected option
    color: "white", // Text color
    "&:hover": {
      backgroundColor: "#555", // Background for hovering
    },
  }),
  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: "#555", // Background for selected options tags
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    color: "white", // Text color for selected options tags
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    color: "white", // Text color for the remove icon in selected options tags
    "&:hover": {
      backgroundColor: "#777", // Background color for the remove icon on hover
      color: "white", // Text color for the remove icon on hover
    },
  }),
};

type IngredientType = { value: string; label: string };

export const AddRecipe = () => {
  const { setActiveSection, editRecipe } = useContext(DashboardContext);

  const [selectedTags, setSelectedTags] = useState<IngredientType[]>(
    editRecipe?.tags?.map((tag) => ({
      value: tag,
      label: tag,
    })) ?? []
  );

  const initialValues: Recipe = {
    id: editRecipe?.id ?? uuidv4(),
    name: editRecipe?.name ?? "",
    ingredients: editRecipe?.ingredients ?? [],
    tags: editRecipe?.tags ?? [],
    instructions: editRecipe?.instructions ?? "",
  };

  return (
    <Formik
      initialValues={editRecipe ? editRecipe : initialValues}
      onSubmit={(values: Recipe) => {
        if (editRecipe) {
          UpdateRecipe(values);
          toast.success("Recipe updated successfully");
          setActiveSection("Recipes");
        } else {
          AddRecipeAPI(values);
          toast.success("Recipe added successfully");
          setActiveSection("Recipes");
        }
      }}
    >
      {({ setFieldValue, values }) => (
        <Form className={CSS.form}>
          <div className={CSS.container}>
            <span className={CSS.formField}>
              <LabelText text="Recipe Name" />
              <Field name="name" as={InputField} />
            </span>

            <span className={CSS.formField}>
              <LabelText text="Ingredients" />
              <Select
                isMulti
                onChange={(value) => {
                  setFieldValue(
                    "ingredients",
                    (value as IngredientType[]).map((ingredient) => ({
                      ingredientName: ingredient.label,
                      quantity:
                        values.ingredients.find(
                          (ing) => ing.ingredientName === ingredient.label
                        )?.quantity ?? 0,
                      unit:
                        values.ingredients.find(
                          (ing) => ing.ingredientName === ingredient.label
                        )?.unit ?? "g",
                      description:
                        values.ingredients.find(
                          (ing) => ing.ingredientName === ingredient.label
                        )?.description ?? "",
                    }))
                  );
                }}
                options={ingredientsData}
                styles={darkThemeStyles}
                value={values.ingredients.map((ing) => ({
                  value: ing.ingredientName,
                  label: ing.ingredientName,
                }))}
              />
            </span>

            <table>
              <thead>
                <tr>
                  <th>Sr.</th>
                  <th>Ingredient Name</th>
                  <th>Quantity</th>
                  <th>Unit</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {values.ingredients.map((ingredient, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{ingredient.ingredientName}</td>
                    <td>
                      <Field
                        type="number"
                        as={InputField}
                        name={`ingredients[${index}].quantity`}
                      />
                    </td>
                    <td className={CSS.selectContainer}>
                      <Dropdown
                        label="Unit"
                        options={Units.map((unit) => unit.value)}
                        onChange={(e) => {
                          setFieldValue(
                            `ingredients[${index}].unit`,
                            e.target.value
                          );
                        }}
                      />
                    </td>
                    <td>
                      <Field
                        as={InputField}
                        name={`ingredients[${index}].description`}
                        placeholder="e.g. chopped, sliced, etc."
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <span className={CSS.formField}>
              <LabelText text="Recipe Tags (Diagnostics or Findings)" />
              <Select
                isMulti
                onChange={(value) => {
                  setSelectedTags(value as IngredientType[]);

                  setFieldValue(
                    "tags",
                    value.map((v) => v.value)
                  );
                }}
                options={RecipeTagsOptions}
                styles={darkThemeStyles}
                value={selectedTags.length > 0 ? selectedTags : undefined}
              />
            </span>

            <span className={CSS.formField}>
              <LabelText text="Instructions (optional)" />
              <Field
                multiline
                placeholder=""
                as={InputField}
                name="instructions"
              />
            </span>
          </div>
          <div className={CSS.buttonRow}>
            <Button
              mode="primary"
              label={editRecipe ? "Update Recipe" : "Save Recipe"}
              type="submit"
            />

            <Button
              mode="secondary"
              label="Cancel"
              type="button"
              onClick={() => {
                setActiveSection("Recipes");
              }}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};
