import { RecipesData } from "../data/sample-data";
import { Recipe } from "../types/recipes";

const STORAGE_KEY = "recipes";

function initializeRecipes() {
  const storedRecipes = localStorage.getItem(STORAGE_KEY);
  if (!storedRecipes) {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(RecipesData));
  }
}

export function GetAllRecipes() {
  initializeRecipes();
  const storedRecipes = localStorage.getItem(STORAGE_KEY);
  return storedRecipes ? JSON.parse(storedRecipes) : [];
}

export function AddRecipe(newRecipe: Recipe) {
  const recipes = GetAllRecipes();
  recipes.push(newRecipe);
  localStorage.setItem(STORAGE_KEY, JSON.stringify(recipes));
}

export function GetRecipeById(recipeId: string) {
  const recipes = GetAllRecipes();
  return recipes.find((recipe: Recipe) => recipe.id === recipeId);
}

export function UpdateRecipe(updatedRecipe: Recipe) {
  const recipes = GetAllRecipes();
  const index = recipes.findIndex(
    (recipe: Recipe) => recipe.id === updatedRecipe.id
  );
  recipes[index] = updatedRecipe;
  localStorage.setItem(STORAGE_KEY, JSON.stringify(recipes));
}

export function DeleteRecipe(recipeId: string) {
  const recipes = GetAllRecipes();
  const index = recipes.findIndex((recipe: Recipe) => recipe.id === recipeId);
  recipes.splice(index, 1);
  localStorage.setItem(STORAGE_KEY, JSON.stringify(recipes));
}
