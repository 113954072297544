import React, { useContext, useEffect, useState } from "react";
import { Recipe } from "../../../types/recipes";
import CSS from "./recipes.module.css";
import { InputField, LabelText } from "../../common/components";
import Button from "../../common/components/button";
import { DashboardContext } from "../../dashboard/dashboard-context";

import { ReportType } from "../../../types/reports";
import { FormikErrors } from "formik";
import { AppContext } from "../../app-context/app-context";
import { PlusCircleIcon } from "lucide-react";

type ViewRecipesProps = {
  path?: string;
  setFieldValue?: (
    field: string,
    value: Recipe
  ) => Promise<void | FormikErrors<ReportType>>;
  onClose?: () => void;
};
export const Recipes = ({ path, setFieldValue, onClose }: ViewRecipesProps) => {
  const { allRecipes: RecipesData } = useContext(AppContext);

  const [filteredRecipes, setFilteredRecipes] = useState<Recipe[]>(RecipesData);
  const { setActiveSection, setEditRecipe } = useContext(DashboardContext);

  let isReportView: boolean = false;

  // Reusing the view recipes component for report view as well for the purpose of selecting a recipe
  // while writing a report for a patient.
  if (path && setFieldValue) {
    isReportView = true;
  }

  // Clearing up saved recipe in edit recipe when a recipe is opened, but then cancelled and user is
  // navigated back to list all recipes page.

  useEffect(() => {
    setEditRecipe(undefined);
  }, []);

  return (
    <div className={CSS.form}>
      {isReportView && <h2>Select a Recipe</h2>}
      <div className={CSS.allRecipesHeader}>
        <span className={CSS.formField}>
          <LabelText text="Search" />
          <InputField
            width={520}
            placeholder="Search for recipes (Name, Findings, Diagnostics, Ingredients, Instructions, Date)"
            onChange={(value) => {
              if (value.target.value && value.target.value !== "") {
                const filtered = RecipesData.filter(
                  (recipe) =>
                    recipe.name
                      .toLowerCase()
                      .includes(value.target.value.toLowerCase()) ||
                    recipe.tags?.some((tag) =>
                      tag
                        .toLowerCase()
                        .includes(value.target.value.toLowerCase())
                    ) ||
                    recipe.ingredients.some((ingredient) =>
                      ingredient.ingredientName
                        .toLowerCase()
                        .includes(value.target.value.toLowerCase())
                    ) ||
                    recipe.instructions
                      ?.toLowerCase()
                      .includes(value.target.value.toLowerCase()) ||
                    recipe.dateCreated === value.target.value
                );
                setFilteredRecipes(filtered);
              } else setFilteredRecipes(RecipesData);
            }}
          />
        </span>

        {!isReportView && (
          <Button
            children={<PlusCircleIcon color="black" />}
            size="large"
            label="Add a new recipe"
            onClick={() => {
              setActiveSection("add-recipes");
            }}
          />
        )}
      </div>
      <div className={CSS.allRecipes}>
        <table>
          <thead>
            <tr>
              <th>Sr.</th>
              <th>Recipe Name</th>
              <th>Ingredients</th>
              <th>Tags</th>
              <th>Date Added</th>
              <th>Instructions</th>
            </tr>
          </thead>
          <tbody>
            {filteredRecipes.map((recipe, index) => (
              <tr
                key={recipe.id}
                onClick={() => {
                  if (isReportView) {
                    setFieldValue && setFieldValue(path!, recipe);
                    onClose && onClose();
                  } else {
                    setEditRecipe && setEditRecipe(recipe);
                    setActiveSection("edit-recipes");
                  }
                }}
              >
                <td>{index + 1}</td>
                <td>{recipe.name}</td>
                <td>
                  {recipe.ingredients
                    .map(
                      (ingredient) =>
                        `${ingredient.quantity} ${ingredient.unit ?? ""} - ${
                          ingredient.ingredientName
                        }`
                    )
                    .join(", ")}
                </td>
                <td>{recipe.tags?.join(", ") ?? "-"}</td>
                <td>{recipe.dateCreated ?? "-"}</td>
                <td>{recipe.instructions ?? "-"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
